import React, { useEffect, useState } from "react";
import "./SingleProduct.css";
import { useDispatch } from "react-redux";
import { cartAddProducts, wishlistAddProducts } from "../../../stores/slice";
import Popup from "../../CartComponents/Popup/Popup";
import Button from "../../Button/Button";
import { GET_METHOD } from "../../../api/api";
import SingleProductImage from "../SingleProductImage/SingleProductImage";
import Loader from "../../Loader/Loader";
import RelatedProducts from "../RelatedProducts/RelatedProducts";
// import MeasurementForm from "../CustomSizeForm/MeasurementForm";
import { useNavigate } from "react-router-dom";
import TabComponent from "../../AboutComponents/TabComponent/TabComponent";
import PopupForm from "../../CartComponents/PopupForm/PopupForm";
import SizeSelector from "./SizeSelector";

export default function SingleProduct({ data }) {
  const [size, setSize] = useState("");
  const [color, setColor] = useState("");
  const [colorValue, setColorValue] = useState("");
  const [sizeValue, setSizeValue] = useState("");
  const [sizeData, setSizeData] = useState("");
  const [optionNameColor, setOptionNameColor] = useState();
  const [optionNameSize, setOptionNameSize] = useState();
  const [quantity, setQuantity] = useState(1);
  const [popup, setPopup] = useState(false);
  const [popupWishList, setPopupWishList] = useState(false);
  const [fieldData, setFieldData] = useState([]);
  const [selectedSizeData, setSelectedSizeData] = useState(null);
  const [multiImages, setMultiImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [popupSucess, setPopupSucess] = useState(false);
  const [popupMessage, setPopMessage] = useState("");
  const itemId = data[0]?.ItemId;
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    setSize("");
    setColor("");
    setSizeData("");
    setQuantity(1);
    setFieldData([]);
    setSelectedSizeData(null);
    setMultiImages([]);
    setLoading(true);
  }, [data]);

  useEffect(() => {
    setLoading(true);
    const fetchFieldData = async () => {
      try {
        const res = await GET_METHOD(
          `https://knitting.azurewebsites.net/api/ECommerceApi/GetItemSpecificationAndFeilds?ItemId=${itemId}&CampusId=50`
        );
        if (res === null) {
          setFieldData([]);
          setLoading(false);
        } else {
          setFieldData(res);
          setLoading(false);
        }
      } catch (error) {
        console.error("Failed to fetch field data:", error);
        setFieldData([]);
      } finally {
        setLoading(false);
      }
    };

    fetchFieldData();
  }, [itemId]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const firstColorOption = fieldData.find(
        (option) => option?.BoxType === 58
      );
      if (firstColorOption) {
        setColor(firstColorOption.FeildId);
        setColorValue(firstColorOption.OptionName);
        setSizeData(firstColorOption);
        setSelectedSizeData(firstColorOption);
        setOptionNameColor(firstColorOption.VariationName);

        const multiImagesRes = await GET_METHOD(
          `https://knitting.azurewebsites.net/api/ECommerceApi/GetFeildMultiImagesAndVideos?FieldId=${firstColorOption.FeildId}&CampusId=50`
        );
        setMultiImages(multiImagesRes || []);
        setLoading(false);
      }

      const firstSizeOption = fieldData.find(
        (option) => option?.BoxType === 59
      );
      if (firstSizeOption) {
        // setSize(firstSizeOption.FeildId);
        // setSizeValue(firstSizeOption.OptionName);
        setOptionNameSize(firstSizeOption.VariationName);
        setLoading(false);
      }
    };

    if (fieldData.length > 0) {
      fetchData();
    }
  }, [fieldData]);

  // console.log(colorValue , sizeValue);

  const incrementQuantity = () =>
    setQuantity((prevQuantity) => prevQuantity + 1);
  const decrementQuantity = () =>
    setQuantity((prevQuantity) => Math.max(prevQuantity - 1, 1));

  const handleColorChange = async (fieldId, OptionName) => {
    setLoading(true);
    setColor(fieldId);
    setColorValue(OptionName);
    const sizeData = fieldData.find((item) => item.FeildId === fieldId);
    setSizeData(sizeData);
    setSelectedSizeData(sizeData);

    try {
      const res = await GET_METHOD(
        `https://knitting.azurewebsites.net/api/ECommerceApi/GetFeildMultiImagesAndVideos?FieldId=${fieldId}&CampusId=50`
      );
      if (res === null) {
        setMultiImages([]);
      } else {
        setMultiImages(res);
      }
    } catch (error) {
      console.error("Failed to fetch multi-images:", error);
      setMultiImages([]);
    } finally {
      setLoading(false);
    }
  };

  const calculateFinalPrice = (discountType, discount, price) => {
    if (discountType === 0) {
      const discountedPrice = price - (discount / 100) * price;
      return discountedPrice;
    } else if (discountType === 1) {
      const discounttedPrice = price - discount;
      return discounttedPrice;
    }
  };

  const calculateDiscountPrice = (discountType, discount, price) => {
    if (discountType === 0) {
      const discountedPrice = (discount / 100) * price;
      return discountedPrice;
    } else if (discountType === 1) {
      const discounttedPrice = discount;
      return discounttedPrice;
    }
  };

  const handleSizeChange = (sizeField, OptionName, VariationName) => {
    setSize(sizeField);
    setSizeValue(OptionName);
    // setOptionNameSize(VariationName);
  };

  const handleAddToCart = (
    img,
    price,
    title,
    id,
    qty,
    category,
    SaleWithOutStock,
    currency,
    discount,
    discountType,
    applyDiscount,
    popup
  ) => {
    if (!sizeValue || !size) {
      setPopupSucess(true);
      setTimeout(() => {
        setPopupSucess(false);
      }, [3000]);
      setPopMessage("Please choose a size to proceed with your selection!");
      return;
    }
    const product = {
      img,
      price,
      title,
      ItemId: id,
      ItemQty: qty,
      CategoryName: category,
      SaleWithOutStock,
      currency,
      purchaseQuantity: quantity,
      applyDiscount: applyDiscount,
      discountedPrice: applyDiscount
        ? calculateFinalPrice(discountType, discount, price)
        : price,
      discount: applyDiscount
        ? calculateDiscountPrice(discountType, discount, price)
        : 0,
      color,
      size,
      colorValue,
      sizeValue,
      optionNameColor,
      optionNameSize,
    };

    dispatch(cartAddProducts(product));

    if (popup) {
      setPopup(true);
      setTimeout(() => setPopup(false), 5000);
    } else {
      navigate("/checkout");
    }
  };

  const handleAddToWishlist = (
    img,
    price,
    title,
    id,
    qty,
    category,
    SaleWithOutStock,
    currency
  ) => {
    const product = {
      img,
      price,
      title,
      ItemId: id,
      ItemQty: qty,
      CategoryName: category,
      SaleWithOutStock,
      currency,
      purchaseQuantity: 1,
    };

    dispatch(wishlistAddProducts(product));
    setPopupWishList(true);
    setTimeout(() => setPopupWishList(false), 5000);
  };

  const filterColorOptions = () =>
    fieldData.some((item) => item?.BoxType === 58);
  const filterSizeOptions = () =>
    fieldData.some((item) => item?.BoxType === 59);

  const handleDescription = (desc) => {
    if (!desc || (typeof desc === "object" && Object.keys(desc).length === 0)) {
      return "";
    }
    const trimmedDesc = desc.trim();
    if (
      trimmedDesc === "<div></div>" ||
      trimmedDesc === "<div><br></div>" ||
      trimmedDesc === ""
    ) {
      return "";
    }

    return desc;
  };

  const finalPriceAfterDiscount = (discountPercent, price) => {
    const discountPrice = (discountPercent / 100) * price;
    const finalPrice = price - discountPrice;
    return finalPrice;
  };

  // console.log(filterColorOptions(), 'color options single product page')
  // console.log(filterSizeOptions(), 'size option single product page')
  console.log(data[0]?.HtmlDescription, "html description");
  // console.log(filterSizeOptions(), "filterSizeOptions");

  return (
    <div className="main-single-product">
      {loading && <Loader />}
      <div className="single-product-except-descrip">
        <div className="main-image-div">
          <SingleProductImage
            itemId={itemId}
            data={data}
            selectedSize={selectedSizeData}
            multiImages={multiImages}
            setMultiImages={setMultiImages}
          />
        </div>
        <div className="single-product-text">
          <div className="single-product-text-2-div">
            <div
              className="card-price-stock-single-product"
              style={
                data[0]?.SaleWithOutStock === false && data[0]?.ItemQty > 0
                  ? { width: "7rem" }
                  : { width: "5.4rem" }
              }
            >
              {data[0]?.SaleWithOutStock === true ? (
                <p className="card-stock-instock"> Instock </p>
              ) : data[0]?.SaleWithOutStock === false &&
                data[0]?.ItemQty > 0 ? (
                <p className="card-stock-instock">
                  {data[0]?.ItemQty} units remaining
                </p>
              ) : (
                <p className="card-stock-outstock">Out of Stock</p>
              )}
            </div>
            <h2 className="single-product-heading">{data[0]?.ItemName}</h2>

            <div
              className={`single-product-mini-description ${isExpanded ? "expanded" : "collapsed"
                }`}
              dangerouslySetInnerHTML={{
                __html: handleDescription(data[0]?.HtmlDescription),
              }}
              style={{ color: "var(--primary-text-color)" }}
            ></div>
            {data[0]?.HtmlDescription &&
              handleDescription(data[0]?.HtmlDescription)?.trim() && (
                <p
                  onClick={() => setIsExpanded(!isExpanded)}
                  style={{
                    marginTop: "10px",
                    color: "var(--primary-text-color)",
                    border: "none",
                    cursor: "pointer",
                    fontSize: "0.8rem",
                  }}
                >
                  {isExpanded ? "Read Less" : "Read More"}
                </p>
              )}

            {data[0]?.ApplyDiscount ? (
              <div className="single-product-price-div">
                {data[0]?.DiscountType === 0 && (
                  <>
                    {data[0]?.ApplyDiscount && (
                      <p className="single-product-price-with-discount">
                        $
                        {finalPriceAfterDiscount(
                          data[0]?.Discount,
                          data[0]?.Price
                        )}
                      </p>
                    )}
                    <div className="single-product-discount-price-div">
                      <p className="single-product-orignal-price">
                        ${data[0]?.Price}
                      </p>
                      <p className="single-product-discount-percentage">
                        {" "}
                        {data[0]?.Discount}% off
                      </p>
                    </div>
                  </>
                )}

                {data[0]?.DiscountType === 1 && (
                  <>
                    <div>
                      <p className="single-product-price-with-discount">
                        ${data[0]?.Price - data[0]?.Discount}
                      </p>
                      <div className="single-product-discount-price-div">
                        <p className="single-product-orignal-price">
                          ${data[0]?.Price}
                        </p>
                        <p className="single-product-discount-percentage">
                          {" "}
                          Flat ${data[0]?.Discount} off{" "}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <p className="single-product-price-with-discount">
                ${data[0]?.Price}
              </p>
            )}
            {filterColorOptions() && (
              <div className="product-option">
                <div>
                  {filterColorOptions() ? (
                    <label className="product-option-label">
                      {optionNameColor}
                    </label>
                  ) : null}
                  <div className="size-options">
                    {fieldData?.map((sizeOption) =>
                      sizeOption?.BoxType === 58 ? (
                        <button
                          key={sizeOption?.FeildId}
                          className={`color-option-btn ${color === sizeOption?.FeildId ? "selected" : ""
                            }`}
                          onClick={() =>
                            handleColorChange(
                              sizeOption?.FeildId,
                              sizeOption?.OptionName
                            )
                          }
                          style={
                            sizeOption?.ColorCode
                              ? { backgroundColor: sizeOption?.ColorCode }
                              : { backgroundColor: sizeOption?.OptionName }
                          }
                        ></button>
                      ) : (
                        ""
                      )
                    )}
                  </div>
                </div>
              </div>
            )}
            {filterSizeOptions() && (
              <div className="product-option">
                <div>
                  <label className="product-option-label">
                    {optionNameSize}
                  </label>
                  <SizeSelector fieldData={fieldData} size={size} handleSizeChange={handleSizeChange} />
                 </div>
              </div>
            )}
            <br />
            {data[0]?.SaleWithOutStock === false && data[0]?.ItemQty === 0 && (
              <p className="out-of-stock-text">
                Sorry for inconvenience, this product is out of stock!
              </p>
            )}
            <div className="cart-function-main-div">
              <div className="cart-function">
                <div className="cart-add-remove">
                  <button
                    onClick={decrementQuantity}
                    className="add-sub-cart-btn"
                  >
                    -
                  </button>
                  <input
                    type="number"
                    className="quantity-input"
                    value={quantity}
                    disabled
                  />
                  <button
                    onClick={incrementQuantity}
                    className="add-sub-cart-btn"
                  >
                    +
                  </button>
                </div>

                <Button
                  onClick={() =>
                    handleAddToCart(
                      color ? sizeData?.DisplayImage : data[0]?.ItemImage,
                      data[0]?.Price,
                      data[0]?.ItemName,
                      data[0]?.ItemId,
                      data[0]?.ItemQty,
                      data[0]?.CategoryName,
                      data[0]?.SaleWithOutStock,
                      data[0]?.Currency,
                      data[0]?.Discount,
                      data[0]?.DiscountType,
                      data[0]?.ApplyDiscount,
                      true
                    )
                  }
                  text="Add to cart"
                  className="add-to-cart-btn"
                  style={{ lineHeight: "0", padding: "0rem" }}
                  disable={
                    data[0]?.SaleWithOutStock === false &&
                    data[0]?.ItemQty === 0
                  }
                />
              </div>

              <div className="wishlist-buy-btn-div">
                <button
                  className="buy-now-btn"
                  disabled={
                    data[0]?.SaleWithOutStock === false &&
                    data[0]?.ItemQty === 0
                  }
                  onClick={() =>
                    handleAddToCart(
                      color ? sizeData?.DisplayImage : data[0]?.ItemImage,
                      data[0]?.Price,
                      data[0]?.ItemName,
                      data[0]?.ItemId,
                      data[0]?.ItemQty,
                      data[0]?.CategoryName,
                      data[0]?.SaleWithOutStock,
                      data[0]?.Currency,
                      false
                    )
                  }
                >
                  Buy It Now
                </button>

                <br />

                <button
                  className="buy-now-btn"
                  onClick={() =>
                    handleAddToWishlist(
                      color ? sizeData?.DisplayImage : data[0]?.ItemImage,
                      data[0]?.Price,
                      data[0]?.ItemName,
                      data[0]?.ItemId,
                      data[0]?.ItemQty,
                      data[0]?.CategoryName,
                      data[0]?.SaleWithOutStock,
                      data[0]?.Currency
                    )
                  }
                >
                  add to wishlist
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="product-description">
        <TabComponent description={handleDescription(data[0]?.Description)} />
      </div>

      {handleDescription(data[0]?.SizeChart) && (
        <div>
          <h1 className="related-products-heading">Size Chart</h1>
          <div className="single-product-size-chart">
            <img
              src={data[0]?.SizeChart}
              alt="product-size-chart"
              className="img-single-product-size-chart"
            />
          </div>
        </div>
      )}

      <div className="related-products-div">
        <h1 className="related-products-heading">Related Products</h1>
        <RelatedProducts categoryId={data[0]?.CategoryId} />
      </div>

      {popup && (
        <Popup
          showPopup={popup}
          message="Product added to cart!"
          onClose={() => setPopup(false)}
          img={color ? sizeData?.DisplayImage : data[0]?.ItemImage}
          title={data[0]?.ItemName}
          usedFor="cart"
        />
      )}

      {popupWishList && (
        <Popup
          showPopup={popupWishList}
          message="Product added to Wishlist!"
          onClose={() => setPopupWishList(false)}
          img={color ? sizeData?.DisplayImage : data[0]?.ItemImage}
          title={data[0]?.ItemName}
          usedFor="wishlist"
        />
      )}

      {popupSucess && (
        <PopupForm
          showPopup={popupSucess}
          message={popupMessage}
          onClose={() => setPopupSucess(false)}
          title="Size Not Selected"
          sucess={false}
        />
      )}
    </div>
  );
}
