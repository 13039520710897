import React, { useEffect, useState } from "react";
import { GET_METHOD } from "../../../api/api";
import Loader from "../../Loader/Loader";
import "./SingleProductImage.css";
import ProductZoomMagnifier from "./ImageModal/ImageZoom";
// import leftArrow from "../../../assets/left-arrow.png";
// import rightArrow from "../../../assets/right-arrow.png";

export default function SingleProductImage({ itemId, data, multiImages, setMultiImages }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeThumbnail, setActiveThumbnail] = useState(0);
  const [loading, setLoading] = useState(true);
  const [visibleThumbnails, setVisibleThumbnails] = useState([0, 1, 2, 3, 4]); // 5 thumbnails

  // Fetch Images
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const res = await GET_METHOD(
        `https://knitting.azurewebsites.net/api/ECommerceApi/GetItemMultiImagesAndVideos?ItemId=${itemId}`
      );
      if (res) {
        const imagesArray = [...res, { Media: data[0]?.ItemImage, Type: 1 }];
        setMultiImages(imagesArray);
      } else {
        setMultiImages([]);
      }
      setLoading(false);
    };
    fetchData();
  }, [itemId, setMultiImages, data]);

  // Handle Thumbnail Click
  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
    setActiveThumbnail(index);

    // Update visible thumbnails
    if (index > visibleThumbnails[4]) {
      const newStart = Math.min(index - 4, multiImages.length - 5);
      setVisibleThumbnails([newStart, newStart + 1, newStart + 2, newStart + 3, newStart + 4]);
    } else if (index < visibleThumbnails[0]) {
      const newStart = Math.max(index, 0);
      setVisibleThumbnails([newStart, newStart + 1, newStart + 2, newStart + 3, newStart + 4]);
    }
  };

  // Next and Previous Thumbnails
  const nextThumbnailSet = () => {
    const maxIndex = multiImages.length - 1;
    if (visibleThumbnails[4] < maxIndex) {
      const newStart = visibleThumbnails[0] + 1;
      setVisibleThumbnails([newStart, newStart + 1, newStart + 2, newStart + 3, newStart + 4]);
    }
  };

  const prevThumbnailSet = () => {
    if (visibleThumbnails[0] > 0) {
      const newStart = visibleThumbnails[0] - 1;
      setVisibleThumbnails([newStart, newStart + 1, newStart + 2, newStart + 3, newStart + 4]);
    }
  };

  // Next and Previous Slide
  const nextSlide = () => {
    const nextIndex = (currentIndex + 1) % multiImages.length;
    handleThumbnailClick(nextIndex);
  };

  const prevSlide = () => {
    const prevIndex = (currentIndex === 0 ? multiImages.length : currentIndex) - 1;
    handleThumbnailClick(prevIndex);
  };

  return (
   <div className="single-product-img">
  {loading && <Loader />}

  {/* Main Slider */}
  <div className="img-slider">
    {multiImages.length > 1 && (
      <button className="prev-btn" onClick={prevSlide}>
        &#10094;
      </button>
    )}
    <div className="slider-wrapper">
      {multiImages.map((img, index) => (
        <div
          key={index}
          className={`slide ${index === currentIndex ? "active" : ""}`}
        >
          {img?.Type === 2 ? (
            <video controls className="video-img-slider">
              <source src={img?.Media} type="video/mp4" />
            </video>
          ) : (
            <ProductZoomMagnifier imageSrc={img?.Media} altText={`Product ${index}`} />
          )}
        </div>
      ))}
    </div>
    {multiImages.length > 1 && (
      <button className="next-btn" onClick={nextSlide}>
        &#10095;
      </button>
    )}
  </div>

  {/* Thumbnail Slider */}
  <div className="thumbnail-set">
    <div className="thumbnail-slider">
      {multiImages.length > 1 && visibleThumbnails[0] > 0 && (
        <button className="thumbnail-slider-nav left" onClick={prevThumbnailSet}>
          &#10094;
        </button>
      )}

      {multiImages.map((img, index) =>
        visibleThumbnails.includes(index - 1) || multiImages.length === 2 ? (
          <div
            key={index}
            className={`thumbnail ${activeThumbnail === index ? "active" : ""}`}
            onClick={() => handleThumbnailClick(index)}
          >
            {img?.Type === 2 ? (
              <video>
                <source src={img?.Media} type="video/mp4" />
              </video>
            ) : (
              <img src={img?.Media} alt={`Thumbnail ${index}`} />
            )}
          </div>
        ) : null
      )}
      {multiImages.length > 5 && visibleThumbnails[4] < multiImages.length - 1 && (
        <button className="thumbnail-slider-nav right" onClick={nextThumbnailSet}>
          &#10095;
        </button>
      )}
    </div>
  </div>
</div>


 
  );
}
