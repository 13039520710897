import React, { useEffect, useRef, useState } from "react";
import "./OurBestSellers.css";
import { useSelector } from "react-redux";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GET_METHOD } from "../../../api/api";
import OurBestSellerCard from "./OurBestSellerCard/OurBestSellerCard";
import Loader from "../../Loader/Loader";
import AllProducts from "../../AllProductsComponents/AllProducts/AllProducts";


export default function OurBestSellers() {
  const category = useSelector((state) => state.store.category);
  const [categoryId, setCategoryId] = useState(category[0]?.Id);
  const [selectedCategory, setSelectedCategory] = useState(
    category.length > 0 ? category[0].CategoryName : ""
  );
  const [products, setProducts] = useState([]);
  const [allproducts, setallProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const sliderRef = useRef(null);

  useEffect(() => {
    if (!category) {
      setLoading(true);
    } else if (category.length > 0) {
      setLoading(false);
      setCategoryId(category[0].Id);
      setSelectedCategory(category[0].CategoryName);
    }
  }, [category]);

  const NextArrow = ({ onClick }) => (
    <div
      className="custom-arrow-our-best-seller next-arrow-our-best-seller"
      onClick={onClick}
    >
      &#10095;
    </div>
  );

  const PrevArrow = ({ onClick }) => (
    <div
      className="custom-arrow-our-best-seller prev-arrow-our-best-seller"
      onClick={onClick}
    >
      &#10094;
    </div>
  );

  const sliderSettings = {
    infinite: products.length > 1,
    speed: 500,
    slidesToShow: products.length === 0 ? 1 : products.length <= 4 ? products.length : 4,
    slidesToScroll: products.length === 0 ? 1 : products.length <= 4 ? products.length : 4,
    nextArrow: <NextArrow onClick={() => sliderRef.current.slickNext()} />,
    prevArrow: <PrevArrow onClick={() => sliderRef.current.slickPrev()} />,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: products.length === 0 ? 1 : products.length < 5 ? products.length : 5,
          slidesToScroll: products.length === 0 ? 1 : products.length < 5 ? products.length : 5,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: products.length === 0 ? 1 : products.length < 4 ? products.length : 4,
          slidesToScroll: products.length === 0 ? 1 : products.length < 4 ? products.length : 4,
        },
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: products.length === 0 ? 1 : products.length <= 2 ? products.length : 3,
          slidesToScroll: products.length === 0 ? 1 : products.length <= 2 ? products.length : 3,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: products.length === 0 ? 1 : products.length <= 2 ? products.length : 3,
          slidesToScroll: products.length === 0 ? 1 : products.length <= 2 ? products.length : 3,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: products.length === 0 ? 1 : products.length <= 2 ? products.length : 3,
          slidesToScroll: products.length === 0 ? 1 : products.length <= 2 ? products.length : 3,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 655,
        settings: {
          slidesToShow: products.length === 0 ? 1 : 2,
          slidesToScroll: products.length === 0 ? 1 : 2,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: products.length === 0 ? 1 : 1,
          slidesToScroll: products.length === 0 ? 1 : 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: products.length === 0 ? 1 : 1,
          slidesToScroll: products.length === 0 ? 1 : 1,
          dots: true,
          arrows: false,
        },
      },
    ],

  };

  const handleCategory = (id, categoryName) => {
    setCategoryId(id);
    setSelectedCategory(categoryName);
  };

  useEffect(() => {
    setLoading(true);
    const fetchProducts = async () => {
      if (categoryId) {
        const res = await GET_METHOD(
          `https://knitting.azurewebsites.net/api/ECommerceApi/GetItemCategoryWise?CampusId=50&CategoryId=${categoryId}`
        );
        setProducts(res || []);
      }
      setLoading(false);
    };
    fetchProducts();
  }, [categoryId, category]);

  useEffect(() => {
    setLoading(true);
    const fetchProducts = async () => {
      if (categoryId) {
        const res = await GET_METHOD(
          `https://knitting.azurewebsites.net/api/ECommerceApi/GetItemCategoryWise?CampusId=50`
        );
        setallProducts(res || []);
      }
      setLoading(false);
    };
    fetchProducts();
  }, [categoryId, category]);
  return (
   
    <div>
      <br/>
      <br/>
     <div className="our-best-seller-text">
      <h2>Our best sellers</h2>
      <AllProducts apiData={allproducts} />
    </div>
 
     <div className="main-our-best-sellers">
      <div className="our-best-seller-text">
        {loading && <Loader />}
        <h2>New Edits</h2>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {category?.slice(0, 3)?.map((cat, index) => {
            return (
              <button
                key={index}
                className={`our-best-seller-btn ${selectedCategory === cat?.CategoryName ? "selected" : ""
                  }`}
                onClick={() => handleCategory(cat?.Id, cat?.CategoryName)}
              >
                {cat?.CategoryName}
              </button>
            );
          })}
        </div>
      </div>
      <br /> <br />
      <div
        className={products.length <= 2 ? "our-best-seller-width-half" : "our-best-seller-width-full"}
      >
        <Slider {...sliderSettings}>
          {products?.map((product, index) => {
            return (
              <OurBestSellerCard
                title={product?.ItemName}
                price={product?.Price}
                img={product?.ItemImage}
                Id={product?.ItemId}
                SaleWithOutStock={product?.SaleWithOutStock}
                ItemQty={product?.ItemQty}
                DiscountWithPrice={product?.DiscountWithPrice}
                Discount={product?.Discount}
                ApplyDiscount={product?.ApplyDiscount}
                DiscountType={product?.DiscountType}
                key={index}
              />
            );
          })}
        </Slider>
      </div>
    </div>
    </div>
  );
}
