import React, { useEffect, useState } from "react";
import "./Header.css";
import img1 from "../../../assets/img1Final.jpg";
import img2 from "../../../assets/img2Final.jpg";
import img3 from "../../../assets/img3Final.jpg";
// import img4 from "../../../assets/bannersLeathersCraft.png";
import img5 from "../../../assets/bannerLeatherCraft2.mp4";
import img1Mob from "../../../assets/img1Mobile.webp";
import img2Mob from "../../../assets/img2Mobile.webp";
import img3Mob from "../../../assets/img3Mobile.webp";
import img4Mob from "../../../assets/BannerLeathersCraftsMobileView.png";
import { useNavigate } from "react-router-dom";

export default function Header() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const images = [
    {
      img: img5, // Video
      mobileImg: img4Mob,
      isVideo: true,
    },
    {
      img: img1,
      heading: "Elevate Your Style with Our Latest Collection",
      mobileImg: img1Mob,
    },
    {
      img: img2,
      heading: "New Collection, Leather Jackets, Bags & Patches for All!",
      mobileImg: img2Mob,
    },
    {
      img: img3,
      heading: "Elevate Your Style with Our Latest Collection",
      para: "Leather Jackets for Men, Women, and Kids, Plus Premium Leather Bags and Custom Leather Patches!",
      mobileImg: img3Mob,
    },
  ];

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentSlide((currentSlide - 1 + images.length) % images.length);
  };

  const navigate = useNavigate();

  return (
    <div className="carousel" style={{ "--current-slide": currentSlide }}>
      <div className="carousel-content" onClick={() => navigate("/shop")}>
        {images.map((image, index) => (
          <div
            className={`carousel-image-container ${
              index === currentSlide ? "active" : ""
            }`}
            key={index}
          >
            {/* Conditionally Render Video or Image */}
            {image.isVideo ? (
              <video
                className="carousel-video"
                autoPlay
                loop
                muted
                playsInline
              >
                <source src={image.img} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={
                  windowWidth < 1025 && image?.mobileImg
                    ? image?.mobileImg
                    : image?.img
                }
                alt={`Slide ${index + 1}`}
                className="carousel-image"
                style={index === 1 || index === 3 ? { objectFit: "cover" } : null}
              />
            )}

            {/* Text Overlay */}
            {(image.heading || image.para) && (
              <div
                className={
                  index === 3
                    ? "carousel-text-center"
                    : index === 2
                    ? "carousel-text-2"
                    : "carousel-text"
                }
              >
                <div>
                  <h2>{image?.heading}</h2>
                  {index === 2 ? <p>{image?.para}</p> : null}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Navigation Buttons */}
      <button className="carousel-button prev" onClick={prevSlide}>
        &#10094;
      </button>
      <button className="carousel-button next" onClick={nextSlide}>
        &#10095;
      </button>
    </div>
  );
}
