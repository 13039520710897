// import { useState } from "react";
import "./Card.css"
import { useNavigate } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';
// import Button from "../../Button/Button"

// { img, price, title, id, ItemQty, CategoryName, SaleWithOutStock, Currency }

export default function Card({ title, img, price, Discount, DiscountType, ApplyDiscount, SaleWithOutStock, ItemQty, id  }) {
    // const [currentImg, setCurrentImg] = useState(img);
    const navigate = useNavigate();

    const replaceSpacesWithHyphens = (str) => {
        return str.replace(/[\s']/g, '-').toLowerCase();
    };

    const finalPriceAfterDiscount = (discountPercent, price) => {
        const discountPrice = (discountPercent / 100) * price;
        const finalPrice = price - discountPrice
        return finalPrice
    }

    const truncateTitle = (title, length) => {
        if (title.length > length) {
            return title.slice(title, length) + '...';
        }
        if (title.length <= length) {
            return title;
        }
    }

    return (
        <div className='main-card'>
        <div 
            onClick={() => navigate(`/${replaceSpacesWithHyphens(title)}/${id}`)}
            style={{ cursor: 'pointer', position: 'relative' }}
        >    
            {/* Discount Label (Top-Left) */}
            {ApplyDiscount && (
                <div className='card-discount-label'>
                    {DiscountType === 0 ? `Flat ${Discount}% off` : `Flat $${Discount} off`}
                </div>
            )}

            {/* Product Image with Zoom Effect on Hover */}
            <div className="image-container">
                <img className='card-img' src={img} alt={title} />
            </div>

            <div className='card-title-stock'>
                <p className='card-title'>{truncateTitle(title, 24)}</p>
                <div>
                    {(SaleWithOutStock === true) || (SaleWithOutStock === false && ItemQty > 0) ?
                        <p className='card-stock-instock'>Instock</p> :
                        <p className='card-stock-outstock'>Out of Stock</p>
                    }
                </div>
            </div>

            <Rating readonly initialValue={4.5} size={17} className='rating-cart' />

            {/* Pricing Section */}
            {ApplyDiscount ?
                <div className='card-price-with-discount-div'>
                    {DiscountType === 0 &&
                        <>
                            <p className='card-price-with-discount'>${finalPriceAfterDiscount(Discount, price)}</p>
                            <div className='card-discount-price-div'>
                                <p className="single-product-orignal-price">${price}</p>
                                <p className='single-product-discount-percentage'> Flat {Discount}% off</p>
                            </div>
                        </>
                    }

                    {DiscountType === 1 &&
                        <>
                            <p className='card-price-with-discount'>${price - Discount}</p>
                            <div className='card-discount-price-div'>
                                <p className="single-product-orignal-price">${price}</p>
                                <p className='single-product-discount-percentage'> Flat ${Discount} off</p>
                            </div>
                        </>
                    }
                </div> :

                <div className='card-discount-only-price-div' >
                    <p className='card-price-with-discount'>${price}</p>
                </div>
            }
        </div>
    </div>
);
};

